import { useAuth, useSite } from '@on3/ui-lib';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import styles from './Login.module.scss';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tp: any;
  }
}

export const PianoLogin = () => {
  const { user } = useAuth();
  const { currentSite } = useSite();
  const homePath = `${currentSite?.isTeam ? currentSite?.url : ''}/`;
  const router = useRouter();

  const onReady = useCallback(() => {
    window.tp = window?.tp || [];
    window.tp.push([
      'init',
      function () {
        window.tp.pianoId.show({
          displayMode: 'inline',
          screen: 'login',
          containerSelector: '#login-form',
        });
      },
    ]);
  }, []);

  useEffect(() => {
    if (user?.a) {
      const returnTo = router.query.returnto as string;

      if (returnTo) {
        router.push(returnTo);
      } else {
        router.push(homePath);
      }
    }
  }, [user, router.query.returnto, router, homePath]);

  return (
    <div className={styles.block}>
      <div className={styles.loginForm} id="login-form" ref={onReady} />
    </div>
  );
};
